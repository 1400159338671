<template>
  <div class="px-1 text-center">
    <ElementContainer
      v-for="el in dataListingToShow"
      :key="el"
      v-bind="$attrs"
      :element="el" />
  </div>
</template>
<script>
export default {
  name: 'ElementsContainer',
  components: {
    ElementContainer: () => import('./ElementContainer'),
  },
  props: {
    dataListingToShow: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
