<template>
  <v-form
    ref="createComment"
    @submit.prevent="manageRowComment">
    <v-menu
      ref="scrollMention"
      v-model="showMenu"
      offset-y
      offset-x
      :position-x="x"
      :position-y="y"
      :nudge-top="calcNudgeTop"
      :close-on-content-click="false">
      <MentionAutocomplete
        v-if="showMenu"
        ref="userMention"
        :resource-id="resourceId"
        :resource-type="resourceType"
        :ref-of-text-area="$refs.commentInput"
        placeholder="Mention an user"
        :search="createComment.message"
        @selectedMention="selectedMentionHandle"
        @closeMentionMenu="showMenu = false"
        @userForMentions="userForMentions" />
    </v-menu>
    <v-textarea
      ref="commentInput"
      v-model="createComment.message"
      :append-icon="!!createCommentMessageErrors.length? 'mdi-alert-circle-outline' : ''"
      :class="{'private-mode': privateMode }"
      :disabled="isDisabledComments"
      :error-messages="createCommentMessageErrors"
      :error="!!createCommentMessageErrors.length"
      :placeholder="placeholder"
      :rows="1"
      class="v-textarea__comments comments-textfield"
      color="black"
      dense
      filled
      hide-details
      no-resize
      @blur="blurCommentTextarea"
      @focus="focus"
      @keydown="inputHandler"
      @keydown.enter.prevent=""
      @keydown.38.prevent="upArrow"
      @keydown.40="downArrow"
      @keydown.shift.50="mention(null, null, $event)"
      @paste="$emit('onPaste', $event)">
      <template #append>
        <div class="comment-btns">
          <template v-if="!hidePrivateButtons">
            <app-icon-btn
              :access="disablePrivateButtons"
              :btn-class="'pointer '.concat(privateMode === 'offer' ? ' brightness-icon__selected' : '', !disablePrivateButtons.allowed ? ' brightness-icon' : ' no-access')"
              :is-icon="false"
              icon-width="20"
              icon-height="20"
              :icon-name="DollarIcon"
              show-tooltip
              top
              @click="disablePrivateButtons.allowed && choosePrivateMode('offer')" />

            <app-icon-btn
              :btn-class="'pointer '.concat(privateMode === 'private' ? ' brightness-icon__selected' : '', userId ? ' brightness-icon' : ' no-access')"
              :disabled="!userId"
              :is-icon="false"
              icon-width="20"
              icon-height="20"
              :icon-name="LockIcon"
              show-tooltip
              top
              @click="userId && choosePrivateMode('private')" />
          </template>

          <v-menu
            v-if="attachFilePermission && !editedRowId"
            v-model="attachDocModal"
            offset-y
            nudge-bottom="10"
            :nudge-right="blur ? 0 : 380"
            :nudge-top="blur ? 0 : 20"
            content-class="edit-menu"
            :close-on-content-click="true">
            <template #activator="{ on }">
              <span>
                <v-img
                  :class="{'brightness-icon__selected' : attachDocModal}"
                  aspect-ratio="1"
                  class="pointer brightness-icon show-on-focus"
                  src="@/assets/icons/link-icon.svg"
                  width="20"
                  @click="openChooseAttach(true)"
                  v-on="on" />
                <v-img
                  :class="{'brightness-icon__selected' : attachDocModal}"
                  aspect-ratio="1"
                  class="pointer brightness-icon hide-on-focus"
                  src="@/assets/icons/link-icon.svg"
                  width="20"
                  @click="openChooseAttach(false)"
                  v-on="on" />
              </span>
            </template>
            <v-card class="nav-card">
              <label
                for="file">
                Your Computer
              </label>
            </v-card>
          </v-menu>

          <v-img
            aspect-ratio="1"
            class="pointer brightness-icon"
            src="@/assets/icons/at-icon.svg"
            width="20"
            @click="mention(null)" />
        </div>

        <v-btn
          :class="editedRowId ? '' : 'icon-send pa-0'"
          :color="createComment.message ? 'blue' : ''"
          class="comment-button"
          elevation="0"
          type="submit"
          text>
          <span v-if="editedRowId">Edit</span>
          <v-img
            v-else
            aspect-ratio="1"
            src="@/assets/icons/send-icon.svg"
            max-width="26" />
        </v-btn>
      </template>
    </v-textarea>
  </v-form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';

import ErrorsText from '@/constants/errors';
import { COMMENT_TYPE } from '@/constants/comments';

import DollarIcon from '@/assets/icons/dollar-icon.svg';
import LockIcon from '@/assets/icons/lock-icon.svg';

import AppCommentsMentionAutocomplete from '@/components/App/AppComments/AppCommentsMentionAutocomplete';

import CommentsResources from '@/mixins/CommentsResources';

import { getElementCoordinates } from '@/utils';

export default {
  name: 'AppCommentsForm',
  components: {
    MentionAutocomplete: AppCommentsMentionAutocomplete,
  },
  mixins: [CommentsResources],
  props: {
    private: {
      type: String,
      default: null,
    },
    editId: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    valueBlur: {
      type: String,
      default: null,
    },
    focusMessage: {
      type: Boolean,
      default: false,
    },
    mentionedUsers: {
      type: Array,
      default: () => [],
    },
    isDisabledComments: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DollarIcon,
      LockIcon,
      nextToken: null,
      drag: false,
      blur: false,
      showMenu: false,
      mentionMessage: null,
      attachDocModal: false,
      x: 0,
      y: 0,
      height: 39,
      calcNudgeTop: 0,
      mentionTrigger: false,
      start: 0,
      end: 0,
      createComment: {
        message: null,
      },
      comments: [],
    };
  },
  validations: {
    createComment: {
      message: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['role', 'isMobile', 'activeHeader']),
    ...mapGetters('ProjectDetails', ['statusOfProject']),
    ...mapGetters(['premiumGroup', 'collaboratorGroup', 'userId']),
    ...mapGetters('UserRoles', ['canSubmitEditCommentPricePrivate', 'usersRolesListInCollection']),
    ...mapGetters('UserRoles', ['findUserRoleInLibraryForActiveHeader']),
    commentType() {
      const { OFFER, TEXT } = COMMENT_TYPE;
      return this.privateMode === OFFER ? OFFER : TEXT;
    },
    privateMode: {
      get() {
        return this.private;
      },
      set(value) {
        this.$emit('update:private', value);
      },
    },
    allMentionUsers: {
      get() {
        return this.mentionedUsers;
      },
      set(value) {
        this.$emit('update:mentinedUsers', value);
      },
    },
    editedRowId: {
      get() {
        return this.editId;
      },
      set(value) {
        this.$emit('update:editId', value);
      },
    },
    hidePrivateButtons() {
      if (this.isProjectType) {
        return !(this.statusOfProject === 'active' && !this.editedRowId);
      }
      return this.editedRowId;
    },
    disablePrivateButtons() {
      let { role } = this;
      const { members } = this.activeHeader || {
      };
      if (this.isCollectionType) {
        role = this.findUserRoleInLibraryForActiveHeader;
        if (members) {
          role = this.usersRolesListInCollection;
        }
      }
      return this.canSubmitEditCommentPricePrivate(role);
    },
    placeholder() {
      let placeholder = '';
      if (this.privateMode === 'offer') {
        placeholder = 'Submit Price (Private): $ 0.00';
      } else if (this.privateMode === 'comment') {
        placeholder = 'Private Comment';
      } else if (this.privateMode === 'private') {
        placeholder = 'Comment (Private):';
      } else {
        placeholder = 'Comment';
      }
      return placeholder + '\n\n @mention the person you are talking to so they are notified ;)';
    },
    createCommentMessageErrors() {
      const errors = [];
      if (!this.$v.createComment.message.$dirty) return errors;
      if (!this.$v.createComment.message.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    attachFilePermission() {
      if (this.isProjectType) {
        return this.statusOfProject === 'active' || (this.role === 'collaborator'
          && (this.premiumGroup || this.collaboratorGroup));
      }
      return this.premiumGroup || this.collaboratorGroup;
    },
  },
  watch: {
    focusMessage(val) {
      if (val) {
        this.$refs.commentInput.focus();
      }
    },
    message: {
      handler(val) {
        this.createComment.message = val;
      },
      immediate: true,
    },
    'createComment.message': {
      handler() {
        const bodyTextArea = this.$refs.commentInput.$refs.input;
        this.start = bodyTextArea.selectionStart;
      },
    },
  },
  methods: {
    ...mapActions([
      'handleError',
    ]),
    ...mapMutations([
      'spinner']),
    focus() {
    },
    choosePrivateMode(mode) {
      if (this.privateMode === mode) {
        this.privateMode = null;
      } else {
        this.privateMode = mode;
      }
    },
    openChooseAttach(blur) {
      this.blur = blur;
      if (!blur) {
        this.$refs.commentInput.blur();
      }
    },
    userForMentions(users) {
      if (!users.length) {
        this.calcNudgeTop = 0;
      } else if (users.length === 1) {
        this.calcNudgeTop = 20;
      } else if (users.length === 2) {
        this.calcNudgeTop = users.length * 50 - 20;
      } else if (users.length <= 4) {
        this.calcNudgeTop = users.length * 50 - 10;
      } else {
        this.calcNudgeTop = 209;
      }
    },
    blurCommentTextarea() {
      this.$emit('update:valueBlur', this.createComment.message);
      this.$emit('update:focusMessage', false);
      this.$nextTick(() => {
        if (this.showMenu) {
          this.$refs.commentInput.focus();
        } else if (this.editedRowId) {
          this.$emit('update:message', null);
          this.clearCommentForm();
        }
      });
    },
    selectedMentionHandle(user, searchString) {
      this.showMenu = false;
      this.mention(user, searchString);
      this.allMentionUsers.push(user);
    },
    inputHandler(e) {
      if (this.isMobile && e?.key === '@') {
        this.mention(null, null, e);
        return;
      }
      if (e?.keyCode === 13) {
        if (this.showMenu) {
          this.$refs.userMention.selectMention();
          return;
        }
        if (!e?.shiftKey) {
          e.preventDefault();
          this.manageRowComment();
          return;
        }
        if (this.createComment?.message) {
          this.createComment.message = `${this.createComment.message}  `;
        }
      }
    },
    upArrow() {
      if (this.showMenu) this.$refs.userMention.userUp();
      this.manageScrollTop({
        value: -60,
      });
    },
    downArrow() {
      if (this.showMenu) this.$refs.userMention.userDown();
      this.manageScrollTop({
        value: 60,
      });
    },
    manageScrollTop({ value } = {
    }) {
      if (!value) return;
      const { $refs } = this.$refs.scrollMention ?? {
      };
      if (!$refs) return;
      const { scrollTop } = $refs.content ?? {
      };
      if (!(scrollTop >= 0)) return;
      this.$refs.scrollMention.$refs.content.scrollTop = scrollTop + value;
    },
    show() {
      let position = null;
      this.syncTime = setTimeout(() => {
        const {
          commentInput = {
          },
        } = this.$refs ?? {
        };
        const {
          $el = {
          },
        } = commentInput ?? {
        };
        position = getElementCoordinates($el);
        this.x = position.x + 20;
        this.y = position.y - 50;
        this.showMenu = true;
      }, 130);
    },
    extractEmails(text) {
      // eslint-disable-next-line
      return text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);
    },
    beforeDestroy() {
      clearTimeout(this.syncTime);
    },
    mention(user, string, e) {
      const bodyTextArea = this.$refs.commentInput.$refs.input;
      const sentence = bodyTextArea.value;
      const lengthOfSentence = sentence.length;
      this.start = bodyTextArea.selectionStart;
      this.userForMentions([]);
      if (this.start === undefined) {
        this.start = 0;
      }
      if (e && e?.key !== '@') {
        return;
      }
      const before = sentence.substr(0, this.start);
      const after = sentence.substr(this.start, lengthOfSentence);
      if (sentence.slice(-1) === '@') {
        // return;
      } else if (!user) {
        if (e && e?.key === '@' && (before[before.length - 1]
          && before[before.length - 1] !== 'undefined') && (before[before.length - 1] !== ' '
          && before[before.length - 1] !== '\n'
        )) {
          return;
        }
        if (!e) {
          if (before[before.length - 1] && before[before.length - 1] !== ' ') {
            this.createComment.message = `${before} @${after}`;
          } else {
            this.createComment.message = `${before}@${after}`;
          }
        }
        this.showMenu = false;
        this.show();
      } else if (user) {
        const id = 'idForString';
        const splited = this.createComment.message.split(' ')
          .map((item, i) => `${id}${i}${item}`);
        const checkLineBreakLength = string.split('\n').length;
        const updatedIndex = splited.indexOf(string);
        splited[updatedIndex] = checkLineBreakLength > 1 ? `\n@${user.email}` : `@${user.email}`;
        const cuttedSplitted = splited
          .map((item, index) => item.includes('idForString')
            ? item.slice(id.length + index.toString().length) : item);
        this.createComment.message = cuttedSplitted.join(' ');
      }
    },
    async manageRowComment() {
      try {
        this.$v.createComment.$touch();
        if (!this.$v.createComment.$invalid) {
          this.spinner(true);
          const splitedMessage = this.createComment.message.split(' ');
          const mentioned = [];
          splitedMessage.forEach((string) => {
            if (string[0] === '@' || (string[0] === '\n' && string[1] === '@')) {
              const email = this.extractEmails(string);
              if (Array.isArray(email)) {
                email.forEach((userEmail) => {
                  const foundUserIndex = this.lodash.findIndex(this.allMentionUsers,
                    {
                      email: userEmail,
                    });
                  if (foundUserIndex >= 0) {
                    mentioned.push(this.allMentionUsers[foundUserIndex].id);
                  }
                });
              }
            }
          });
          if (this.editedRowId) {
            this.$emit('editComment', {
              mentioned,
              message: this.createComment.message,
              commentId: this.editedRowId,
            });
          } else {
            return new Promise((resolve) => this.$emit('createComment', {
              resolve,
              mentioned,
              message: this.createComment.message,
              commentType: this.commentType,
            }));
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    clearCommentForm() {
      this.$refs.createComment.reset();
      this.$v.createComment.$reset();
      this.allMentionUsers = [];
      this.privateMode = null;
      this.editedRowId = null;
    },
  },
};
</script>
<style scoped lang="scss">
.show-more-comments {
  color: var(--v-blue-base);
  &:hover {
    opacity: 0.6;
  }
}
.edited-comment {
  border: 1px solid var(--v-blue-base);
  opacity: 0.8;
}
</style>
