<template>
  <app-action-btn
    v-if="item.kind !== 'default'"
    :access="canWorkWithModifyingLibrary"
    :wrapper-class="wrapperClass"
    bottom
    show-tooltip
    @click="canWorkWithModifyingLibrary.allowed && deleteLibrary(item.id)">
    <template
      v-if="icon"
      #iconWrapper>
      <v-icon
        :size="iconSize"
        :color="iconColor">
        mdi-delete
      </v-icon>
    </template>
    <slot />
  </app-action-btn>
</template>
<script>
import {
  mapActions,
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import LibrariesApi from '@/services/graphql/libraries';
export default {
  name: 'LibraryDelete',
  props: {
    iconColor: {
      type: String,
      default: 'lightBlack',
    },
    replaceRouter: {
      type: Object,
      default: () => null,
    },
    iconSize: {
      type: String,
      default: '16',
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    icon: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserRoles', ['canModifyCustomLibrary', 'findUserRoleInLibraryForActiveHeader']),
    canWorkWithModifyingLibrary() {
      return this.canModifyCustomLibrary(this.item.type ?? this.findUserRoleInLibraryForActiveHeader);
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      newLibraryList: 'Libraries/newLibraryList',
      handleError: 'handleError',
    }),
    async deleteLibrary(libraryId) {
      let confirmLibraryDelete = await this.$openConfirm({
        text: `Do you want to delete this library ?`,
      });
      if (confirmLibraryDelete) {
        this.spinner(true);
        try {
          await LibrariesApi.deleteLibrary({
            libraryId,
            workspaceId: this.activeWorkspaceId,
          });
          await this.newLibraryList(libraryId);
          if (this.replaceRouter) {
            this.$router.push(this.replaceRouter);
          }
        } catch (err) {
          this.handleError(err);
        } finally {
          this.spinner(false);
        }
      }
    },
  },
};
</script>
<style scoped>
</style>
