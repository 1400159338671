<template>
  <div
    class="dialog-content"
    @click="onFocusIn">
    <v-tooltip
      :disabled="canWorkWithProjectDocuments.allowed"
      content-class="btn-tooltip"
      bottom
      activator="#attach-collection-details">
      <span>{{ GET_TEXT_REASON(canWorkWithProjectDocuments.reason) }}</span>
    </v-tooltip>
    <v-card
      id="card"
      @paste="onPaste">
      <v-card-title>
        <div class="d-flex align-center">
          <span>
            {{ nameTitle }}
          </span>
          <app-focus-warning-input />
        </div>
        <span
          class="sourcery__icon-wrapper black"
          @click="$emit('closeDialog',false)">
          <v-icon
            color="white"
            size="15">
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <div
        id="attach-collection-details"
        class="d-block">
        <app-focus-warning :show-warning="showFocusWarning" />
        <file-upload
          ref="upload"
          v-model="files"
          class="file-uploads"
          style="cursor: pointer"
          chunk-enabled
          :disabled="!canWorkWithProjectDocuments.allowed"
          input-id="projectDocument"
          :multiple="true"
          :drop="true"
          :drop-directory="false">
          <img
            src="@/assets/icons/dragAndDrop.png"
            alt="dragAndDrop">
          <span :style="!canWorkWithProjectDocuments.allowed && {color: 'grey'}">Copy and paste or drag and drop documents here or click to upload</span>
        </file-upload>
      </div>
      <v-card-text>
        <div class="files-wrapper">
          <div
            v-for="(val , i ) in images"
            :key="`val-${i}`"
            :style="!checkIsAirtableItemToRemove(val.id) ? '' : 'display:none'"
            class="file">
            <template>
              <div
                class="file__data">
                <div>
                  <div
                    v-if="val.type === 'application/pdf'"
                    class="icon-wrapper">
                    <div
                      v-if="val.url"
                      class="icon-wrapper__pdf">
                      <app-proxy-pdf
                        class="file-image__pdf"
                        :src="val.url" />
                    </div>
                    <v-icon
                      v-else
                      size="50">
                      mdi-file-pdf
                    </v-icon>
                  </div>
                  <img
                    v-else-if="isImage(val)"
                    :src="val.url"
                    class="file-image"
                    @error="$emit('error', val)">
                  <img
                    v-else-if="val.url"
                    :src="val.url"
                    class="file-image">
                  <img
                    v-else
                    width="32"
                    height="32"
                    src="@/assets/icons/file-icon.svg">
                </div>
                <div class="ml-1">
                  {{ val.filename }}
                </div>
              </div>
              <div
                class="buttons">
                <div
                  style="top: 45px;"
                  class="pointer"
                  @click="downloadItem(val, $event)">
                  <v-icon
                    color="#D9DCDD">
                    mdi-folder-download
                  </v-icon>
                </div>
                <app-icon-btn
                  :access="canWorkWithProjectDocuments"
                  top
                  show-tooltip
                  icon-name="mdi-close-circle"
                  icon-color="#D9DCDD"
                  icon-height="19"
                  class="pointer"
                  @click="canWorkWithProjectDocuments.allowed && onFileRemove(val.id , true)" />
              </div>
            </template>
          </div>
          <div
            v-for="(file , i ) in files"
            :key="`file-${i}`"
            class="file">
            <div class="file__data">
              <div>
                <div
                  v-if="file.type === 'application/pdf'">
                  <div
                    v-if="readFileURL(file.file)">
                    <app-proxy-pdf
                      v-if="useNewPdfLibrary"
                      :src="readFileURL(file.file)"
                      class="file-image__pdf"
                      @progress="onPdfProgress" />
                  </div>
                  <v-icon
                    v-else
                    size="50">
                    mdi-file-pdf
                  </v-icon>
                </div>
                <img
                  v-else-if="isImage(file)"
                  :src="readFileURL(file.file)"
                  class="file-image"
                  @error="$emit('error', file)">
                <img
                  v-else
                  width="32"
                  height="32"
                  src="@/assets/icons/file-icon.svg">
              </div>
              <div class="ml-1">
                {{ file.name }}
              </div>
            </div>
            <div
              class="buttons">
              <app-icon-btn
                :access="canWorkWithProjectDocuments"
                top
                show-tooltip
                icon-name="mdi-close-circle"
                icon-color="#D9DCDD"
                icon-height="19"
                class="pointer"
                @click="canWorkWithProjectDocuments.allowed && onFileRemove(file.name)" />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!canWorkWithProjectDocuments.allowed"
          class="white--text"
          color="blue"
          elevation="0"
          @click="chooseAction">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {
  mapActions, mapMutations,
} from 'vuex';
import { GET_TEXT_REASON } from '@/constants/userPermissions';
import { parseStorageKey } from '@/utils';
import { Storage } from 'aws-amplify';
import PasteClipboard from '@/mixins/PasteClipboard';
import fileProgressMixin from '@/mixins/fileProgressMixin';
import FileUpload from 'vue-upload-component';
import AppFocusWarning from '@/components/App/AppFocusWarning';
import FocusOutModal from '@/mixins/FocusOutModal';
import AppFocusWarningInput from '@/components/App/AppFocusWarningInput';
import appProxyPdf from '@/components/App/AppProxyPdf';
export default {
  name: 'ContentDownloadDocument',
  components: {
    FileUpload,
    AppFocusWarning,
    AppFocusWarningInput,
    appProxyPdf,
  },
  mixins: [PasteClipboard, fileProgressMixin, FocusOutModal],
  props: {
    nameTitle: {
      type: String,
      default: 'Collection Photos',
    },
    images: {
      type: Array,
      default: () => [],
    },
    canWorkWithProjectDocuments: {
      type: Object,
      default: () => {
      },
    },
    useNewPdfLibrary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      itemsToDelete: [],
    };
  },
  created() {
    this.createFocusEvent();
  },
  destroyed() {
    this.removeFocusEvent();
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['downloadFileByBlob']),
    GET_TEXT_REASON,
    isImage({ type } = {
    }) {
      return type?.includes('image');
    },
    checkIsAirtableItemToRemove(fileId) {
      return this.itemsToDelete.find((i) => i === fileId);
    },
    onFileRemove(filename, isAirtableImage) {
      if (isAirtableImage) {
        this.itemsToDelete.push(filename);
      } else {
        this.files = this.files.filter((f) => f.name !== filename);
      }
    },
    readFileURL(file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      return path;
    },
    async saveFile() {
      this.$emit('saveProjectDocuments', this.files);
      this.$emit('closeDialog', false);
      this.files = [];
    },
    async downloadItem(item, e) {
      e.preventDefault();
      const { identityId } = parseStorageKey(item.key);
      const path = `${item.id}/${item.filename}`;
      item.url = await Storage.get(
        path, {
          level: 'protected',
          identityId,
        }
      );
      const { type, filename, url } = item;
      await this.downloadFileByBlob({
        type,
        filename,
        url,
      });
    },
    async deleteFiles() {
      this.$emit('deleteFiles', this.itemsToDelete);
      this.$emit('closeDialog', false);
      this.itemsToDelete = [];
    },
    chooseAction() {
      if (this.files.length && this.itemsToDelete.length) {
        this.deleteFiles();
        this.saveFile();
      } else if (this.files.length) {
        this.saveFile();
      } else if (this.itemsToDelete.length) {
        this.deleteFiles();
      }
    },
  },
};
</script>
<style scoped>
</style>
