<template>
  <div
    v-intersect.once="onIntersect"
    class="block-element"
    :class="{
      'block-element__search-hightlighting__selected': item.isSelectedCellSearchHighlighting,
      'block-element__search-hightlighting': item.isCellSearchHighlighting,
    }"
    @click="openWindow">
    <slot
      :id="item.id"
      name="badge" />

    <!-- image -->
    <div class="block-element__image-wrapper">
      <div class="verified-check">
        <AppVerifiedMark
          :is-verified="isVerified"
          :child-verified-ws="item.childVerified"
          :verified-page-id="item.verifiedPageId"
          :verified-page-name="item.verifiedPageName" />
      </div>
      <div class="block-element__image">
        <ElementImage
          :is-shown="isShown"
          :height-of-image="imageHeight"
          :items="imageArray" />
      </div>
    </div>

    <!-- text -->
    <ElementsContainer
      :data-listing-to-show="dataListingToShow"
      :item="item" />
    <slot
      :item="item"
      name="bottomAction" />
  </div>
</template>
<script>
import ElementImage from './ElementImage';
import ElementsContainer from './ElementsContainer';
import productHeaders from '@/constants/productHeaders';
import AppVerifiedMark from '@/components/App/AppVerifiedMark';
export default {
  name: 'BlockElement',
  components: {
    AppVerifiedMark,
    ElementImage,
    ElementsContainer,
  },
  props: {
    dataListingToShow: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    allowIntersect: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    productTypeItems() {
      const { [productHeaders.PRODUCT_TYPE]: items = [] } = this.item ?? {
      };
      if (!Array.isArray(items) || !items) return [];
      return items;
    },
    imageArray() {
      return this.item['Image'] || [];
    },
    imageArrayLength() {
      return this.imageArray.length;
    },
    imageHeight() {
      const itemUnit = this.imageArray[0];
      if (itemUnit?.thumbnails?.large?.width) {
        const widthPersentage = (300 * 100) / itemUnit?.thumbnails?.large?.width;
        const heightPersentage = (widthPersentage * itemUnit?.thumbnails?.large?.height) / 100;
        return `height: ${heightPersentage}px`;
      }
      return 'height: auto';
    },
    isVerified() {
      return Boolean(this.item?.isVerified);
    },
  },
  methods: {
    openWindow() {
      this.$emit('openUpdateWindow', this.item);
    },
    onIntersect(entries, observer, isIntersecting) {
      if (!this.allowIntersect) {
        return;
      }
      this.$emit('onIntersect', {
        entries, observer, isIntersecting,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.block-element {
  width: 300px;
  gap: 16px;
  overflow-wrap: anywhere;
  align-content: start;
  margin: 15px;
  position: relative;
  cursor: pointer;
  display: grid;
  border-radius: 15px;
  border: 1px solid rgba(12, 12, 12, 0.25);
  padding: 10px 10px 16px;
  background-color: var(--v-white-base);

  &__image-wrapper {
    position: relative;
    width: 100%;
  }

  &__image {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }

  &__search-hightlighting {
    background: #e9d8094f;
    &__selected {
      background: #e9d8099e;
    }
  }

  ::v-deep.verified-check {
    position: absolute;
    z-index: 4;
    top: 10px;
    right: 10px;
  }
}
</style>
